.container-slider {
  width: 350px;
  height: 220px;
  position: relative;
  overflow: hidden;
  margin: 2px;
}

@media screen and (max-width: 700px) {
  .container-slider {
    width: 300px;
    height: 195px;
    position: relative;
    overflow: hidden;
    margin: 2px;
  }
}
@media only screen and (max-width: 1024px) {
  .slider-car-item {
    margin-bottom: 2rem !important;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 0px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 50%;
  background: white;
  left: 0px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 0px;
  background: white;
  transform: translateY(-60%);
}

.slider-car-item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgb(56 125 255 / 17%);
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  flex-flow: column;
}

.slider-info {
  padding: 15px 10px;
}

.slider-info-title {
  color: #252e48;
  font-size: 25px;
  font-weight: bold;
  padding: 10px 0;
}

.slider-info-content {
  color: #252e48;
  padding: 0 0 5px 0;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}
