.form-input-large{
    height: 35px;
    /* width: 350px; */
    border-radius: 3px;
    border: 0.5px solid black;
    padding: 2px 5px;
    margin: 5px 0px;
}

.form-input-select {
    height: 35px;
    /* width: 350px; */
    margin: 5px 0px;
}

.form-input-textarea {
    resize: none;
    height: 100px;
    /* //width: 350px; */
    margin: 5px 0px;
}

.form-container {
    padding: 15px 0;
    display: grid;
    grid-template-columns: 1fr;
}

.temp--padding {
    padding: 15px 0 0;
}

@media screen and (min-width: 600px) {

    .temp--padding {
        padding: 35px 0;
    }
}