.wrapper {
  /* margin: 2rem; */
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  justify-content: center;
}

.card__img_wrap {
  margin: 20px 5px;
}

.card {
  box-shadow: 0 0 10px 5px #e1e5ee;
  border-radius: 0.2rem;
  /* min-width: 28rem; */
  min-width: 14rem;
  display: flex;
}
.card__img {
  /* width: 14rem;
  height: 11rem;
  object-fit: cover; */
  width: 7rem;
  height: 9rem;
  object-fit: cover;
}

.card__body {
  margin: 1rem;
  flex-grow: 1;
}
.card__title {
  /* line-height: 1.4rem;
  margin-bottom: 0.5rem; */
  font-size: 18px;
  text-align: start;
  line-height: 1.7rem;
  margin-bottom: 0.5rem;
}
.card__description {
  line-height: 1.2rem;
  font-size: 14px;
  text-align: justify;
}
.card__price {
  font-size: 1.4rem;
  margin-top: 0.6rem;
}
.card__btn {
  border: none;
  border-top: 1px solid #e1e5ee;
  background-color: transparent;
  font-family: inherit;
  font-size: 1rem;
  font-weight: bold;
  color: inherit;
  width: 100%;
  padding-top: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}
.card__line {
  margin: 15px 0px;
  border-top: 1px solid #e1e5ee;
}

.card__bottom {
  text-align: left;
}

@media screen and (min-width: 600px) {
  .wrapper {
    /* grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem)); */
    grid-template-columns: repeat(auto-fit, minmax(21rem, 30rem));
  }

  .card {
    flex-direction: column;
    text-align: center;
    min-width: 14rem;
  }
  .card__img {
    width: 100%;
    height: 20rem;
  }
  .card__img_wrap {
    margin: 0;
  }

  .filters {
    display: inline-flex;
    padding: 25px 0 25px 10%;
  }

  .filter_chip {
    font-family: Times;
    font-size: 14px;
    margin-right: 20px;
    border: none;
    padding: 10px 20px;
    box-shadow: 1px 1px 4px 2px #b9bcbc;
    border-radius: 0.2em;
    color: #555555;
    cursor: pointer;
    background-color: white;
  }
}

.block-page {
  margin: 2rem;
  min-height: 70vh;
}

.filter_chip {
  font-family: Times;
  font-size: 14px;
  margin-right: 20px;
  border: none;
  padding: 5px 15px;
  box-shadow: 1px 1px 4px 2px #b9bcbc;
  border-radius: 0.2em;
  color: #555555;
  cursor: pointer;
  background-color: white;
}

.filter_chip > a {
  text-decoration: none;
  color: #555555;
}

.chip_selected {
  background: #555555;
  color: white;
  box-shadow: 1px 1px 4px 2px #b9bcbc;
}

.filters {
  display: inline-flex;
  padding: 25px 0 5px 10%;
}
