.section-header {
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 1.1;
  font-weight: 600;
  color: #1c2237;
}

.section-content {
  margin-bottom: 15px;
  padding: 0 8em;
  flex: 1;
  max-width: 100%;
  flex-basis: 100%;

  /* @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  } */
}

@media screen and (max-width: 768px) {
  .section-content {
    margin-bottom: 15px;
    
    padding-right: 25px;
    padding-left: 25px;
    flex: 1;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.service-form {
  padding: 25px 275px;
}

@media screen and (max-width: 960px) {
  .service-form {
    padding: 25px 25px;
  }
}
