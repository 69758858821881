.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  opacity: 1;
  animation: keyframes-show-0-2-5 0.5s ease;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 0.625rem rgb(0 0 0 / 20%);
  position: relative;
  
  /* p:last-of-type {
      margin-bottom;: 0
    } */
}

@media (min-width: 576px) {
  .modal {
    background-color: #fff;
    box-shadow: 0 0 0.625rem rgb(0 0 0 / 20%);
    position: relative;
    
    width: 50%;
  }
}

@keyframes keyframes-show-0-2-5 {
  0% {
    display: "none";
    opacity: 0;
  }
  1% {
    display: "flex";
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-img {
  height: auto;
  max-width: 100%;
  display: block;
  width: -webkit-fill-available;
}
