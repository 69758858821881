@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);
* {
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }

  .btn--thick {
    background-color: black;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .btn--thick:hover{
    
    transition: all 0.3s ease-out;
    background-color: black;
    color: #fff;
    /* background: #fff;
    color: #242424;
    border: 1px solid #242424; */
    transition: 250ms;
  }

  

.hero-container {
    /* background: url('/images/construction-bg.webp') center center/cover no-repeat; */
    height: 85vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    text-align: center;
  }

  .mainpage-bg {
    background: url('/images/construction-bg.webp') center center/cover no-repeat;
    /* background: url('/images/interior.webp') center center/cover no-repeat; */
  }
  .servicepage-bg {
    background: url('/images/service-home.jpeg') center center/cover no-repeat;
  }
  
  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
  
.cards {
    padding: 4rem 0 0;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    -webkit-animation-name: fade-img;
            animation-name: fade-img;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    /* background-color: #1f98f4; */
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 15px;
    line-height: 24px;
  }

  .cards__item__title {
    color: #252e48;
    font-size: 25px;
    font-weight: bold;
    padding: 10px 0px 10px;
    /* line-height: 24px; */
  }

  .service-title{
    color: black;
    font-size: 45px;
  }

  .about_us_desc {
    padding: 0px 9em;
    color: black;
  }
  
  @media only screen and (min-width: 1200px) {
    .service-title {
      color: black;
      font-size: 95px;
      text-align: center;
      width: 300px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
    .about_us_desc {
      padding: "0px 6em";
      color: 'black';
    }
  }

  @media only screen and (max-width: 1024px) {
   
    .about_us_desc {
      padding: 0px 3em;
      color: black;
    }
  }


  
.form-input-large{
    height: 35px;
    /* width: 350px; */
    border-radius: 3px;
    border: 0.5px solid black;
    padding: 2px 5px;
    margin: 5px 0px;
}

.form-input-select {
    height: 35px;
    /* width: 350px; */
    margin: 5px 0px;
}

.form-input-textarea {
    resize: none;
    height: 100px;
    /* //width: 350px; */
    margin: 5px 0px;
}

.form-container {
    padding: 15px 0;
    display: grid;
    grid-template-columns: 1fr;
}

.temp--padding {
    padding: 15px 0 0;
}

@media screen and (min-width: 600px) {

    .temp--padding {
        padding: 35px 0;
    }
}
.section-header {
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 1.1;
  font-weight: 600;
  color: #1c2237;
}

.section-content {
  margin-bottom: 15px;
  padding: 0 8em;
  flex: 1 1;
  max-width: 100%;
  flex-basis: 100%;

  /* @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  } */
}

@media screen and (max-width: 768px) {
  .section-content {
    margin-bottom: 15px;
    
    padding-right: 25px;
    padding-left: 25px;
    flex: 1 1;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.service-form {
  padding: 25px 275px;
}

@media screen and (max-width: 960px) {
  .service-form {
    padding: 25px 25px;
  }
}

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  opacity: 1;
  -webkit-animation: keyframes-show-0-2-5 0.5s ease;
          animation: keyframes-show-0-2-5 0.5s ease;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 0.625rem rgb(0 0 0 / 20%);
  position: relative;
  
  /* p:last-of-type {
      margin-bottom;: 0
    } */
}

@media (min-width: 576px) {
  .modal {
    background-color: #fff;
    box-shadow: 0 0 0.625rem rgb(0 0 0 / 20%);
    position: relative;
    
    width: 50%;
  }
}

@-webkit-keyframes keyframes-show-0-2-5 {
  0% {
    display: "none";
    opacity: 0;
  }
  1% {
    display: "flex";
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes keyframes-show-0-2-5 {
  0% {
    display: "none";
    opacity: 0;
  }
  1% {
    display: "flex";
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-img {
  height: auto;
  max-width: 100%;
  display: block;
  width: -webkit-fill-available;
}

.container-slider {
  width: 350px;
  height: 220px;
  position: relative;
  overflow: hidden;
  margin: 2px;
}

@media screen and (max-width: 700px) {
  .container-slider {
    width: 300px;
    height: 195px;
    position: relative;
    overflow: hidden;
    margin: 2px;
  }
}
@media only screen and (max-width: 1024px) {
  .slider-car-item {
    margin-bottom: 2rem !important;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 0px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 50%;
  background: white;
  left: 0px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 0px;
  background: white;
  transform: translateY(-60%);
}

.slider-car-item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgb(56 125 255 / 17%);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
          filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  flex-flow: column;
}

.slider-info {
  padding: 15px 10px;
}

.slider-info-title {
  color: #252e48;
  font-size: 25px;
  font-weight: bold;
  padding: 10px 0;
}

.slider-info-content {
  color: #252e48;
  padding: 0 0 5px 0;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.main-container {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}
.map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}

.contact-container {
  /* text-align: center; */
}

.contact-address {
  line-height: 1.5;
  margin: 25px 20px;
}

@media screen and (min-width: 600px) {
  .contact-address {
    line-height: 1.5;
    margin: 65px 35px;
  }
}

.contact-address > h3 {
  font-size: xx-large;
}

.contact-form-container {
  padding: 25px 50px 20px 20px;
}

.title-bar {
    font-size: 35px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}
.wrapper {
  /* margin: 2rem; */
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr;
  justify-content: center;
}

.card__img_wrap {
  margin: 20px 5px;
}

.card {
  box-shadow: 0 0 10px 5px #e1e5ee;
  border-radius: 0.2rem;
  /* min-width: 28rem; */
  min-width: 14rem;
  display: flex;
}
.card__img {
  /* width: 14rem;
  height: 11rem;
  object-fit: cover; */
  width: 7rem;
  height: 9rem;
  object-fit: cover;
}

.card__body {
  margin: 1rem;
  flex-grow: 1;
}
.card__title {
  /* line-height: 1.4rem;
  margin-bottom: 0.5rem; */
  font-size: 18px;
  text-align: start;
  line-height: 1.7rem;
  margin-bottom: 0.5rem;
}
.card__description {
  line-height: 1.2rem;
  font-size: 14px;
  text-align: justify;
}
.card__price {
  font-size: 1.4rem;
  margin-top: 0.6rem;
}
.card__btn {
  border: none;
  border-top: 1px solid #e1e5ee;
  background-color: transparent;
  font-family: inherit;
  font-size: 1rem;
  font-weight: bold;
  color: inherit;
  width: 100%;
  padding-top: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}
.card__line {
  margin: 15px 0px;
  border-top: 1px solid #e1e5ee;
}

.card__bottom {
  text-align: left;
}

@media screen and (min-width: 600px) {
  .wrapper {
    /* grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem)); */
    grid-template-columns: repeat(auto-fit, minmax(21rem, 30rem));
  }

  .card {
    flex-direction: column;
    text-align: center;
    min-width: 14rem;
  }
  .card__img {
    width: 100%;
    height: 20rem;
  }
  .card__img_wrap {
    margin: 0;
  }

  .filters {
    display: inline-flex;
    padding: 25px 0 25px 10%;
  }

  .filter_chip {
    font-family: Times;
    font-size: 14px;
    margin-right: 20px;
    border: none;
    padding: 10px 20px;
    box-shadow: 1px 1px 4px 2px #b9bcbc;
    border-radius: 0.2em;
    color: #555555;
    cursor: pointer;
    background-color: white;
  }
}

.block-page {
  margin: 2rem;
  min-height: 70vh;
}

.filter_chip {
  font-family: Times;
  font-size: 14px;
  margin-right: 20px;
  border: none;
  padding: 5px 15px;
  box-shadow: 1px 1px 4px 2px #b9bcbc;
  border-radius: 0.2em;
  color: #555555;
  cursor: pointer;
  background-color: white;
}

.filter_chip > a {
  text-decoration: none;
  color: #555555;
}

.chip_selected {
  background: #555555;
  color: white;
  box-shadow: 1px 1px 4px 2px #b9bcbc;
}

.filters {
  display: inline-flex;
  padding: 25px 0 5px 10%;
}

