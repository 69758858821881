.main-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}
.map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}

.contact-container {
  /* text-align: center; */
}

.contact-address {
  line-height: 1.5;
  margin: 25px 20px;
}

@media screen and (min-width: 600px) {
  .contact-address {
    line-height: 1.5;
    margin: 65px 35px;
  }
}

.contact-address > h3 {
  font-size: xx-large;
}

.contact-form-container {
  padding: 25px 50px 20px 20px;
}
